import { Link } from "gatsby";
import { FC } from 'react';
import { Divider, Header } from "semantic-ui-react";
import Layout from "../../components/layout/Layout";

export const ErrorPage: FC<{ status: number, text: string }> = ({ status, text }) => {
  return <Layout>
    <Header as="h2" style={{ margin: "2.575px 0 16.575px" }}>
      Błąd&nbsp;{ status }&thinsp;&mdash;&thinsp;{ text }
    </Header>
    <Divider />
    <p>Wróć do <Link to="/">strony głównej</Link></p>
  </Layout>;
};

const NotFoundPage = () => <ErrorPage status={404} text="Nie znaleziono strony" />;

export default NotFoundPage;
export const Head = () => <title>Nie znaleziono strony</title>;
